import Time from '@web/common/Time'
import { computed, ref } from 'vue'

export function prettyDate (date) {
  return Time(date).format('DD MMMM, H:mm')
}

export default function useChatMessage () {
  function moment (date) {
    return Time(date)
  }

  function msg (str) {
    const msg = str || ''
    const linkRegEx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
    return msg
      .replace(linkRegEx, (match) => `<a href="${match}" target="_blank">${match}</a>`)
      .replace(/(?:\r\n|\r|\n)/g, '<br />')
  }

  function getImages (files) {
    return files.filter(({ type }) => type === 'image')
  }
  function getTypeVideo (files) {
    return files.filter(({ type }) => type === 'video')
  }
  function getConvertingVideo (files) {
    return files.filter(({ status, type }) => [1, 2].includes(status) && type === 'video')
  }
  function getFailedVideo (files) {
    return files.filter(({ status, type }) => status === 4 && type === 'video')
  }
  function getVideos (files) {
    return getTypeVideo(files).filter(({ url }) => url.search('mp4') > 0)
  }
  function getDocs (files) {
    return getTypeVideo(files).filter(({ url }) => url.search('mp4') === -1)
  }
  function getDocuments (files) {
    return files.filter(({ type }) => type === 'document')
  }

  const image = ref(null)
  const video = ref(null)
  const modalValue = computed(() => {
    if (image.value) {
      return {
        type: 'image',
        value: image.value
      }
    } else if (video.value) {
      return {
        type: 'video',
        value: video.value
      }
    }

    return null
  })
  const showModal = computed({
    get: () => !!modalValue.value,
    set: (val) => {
      if (!val) {
        image.value = null
        video.value = null
      }
    }
  })
  function showImage (file) {
    image.value = file.urls.large
  }
  function showVideo (file) {
    video.value = file.url
  }

  return {
    moment,
    msg,
    getImages,
    getTypeVideo,
    getConvertingVideo,
    getFailedVideo,
    getVideos,
    getDocs,
    getDocuments,

    modalValue,
    showModal,
    showImage,
    showVideo
  }
}
